import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/css/style.css';
import { Amplify } from 'aws-amplify';
import GlobalContextProvider from '@root/contexts/global.context';
import Routes from '@root/routes/routes';

const {
  REACT_APP_AWS_REGION,
  REACT_APP_AWS_USERPOOLID,
  REACT_APP_AWS_USERPOOLWEBCLIENTID,
  REACT_APP_VERSION,
  REACT_APP_ENV,
} = process.env;

if (REACT_APP_ENV !== 'local') {
  document.domain = 'addworking.io';
}

const addVersion = () => {
  const meta = document.querySelector('meta[name="version"]');
  if (REACT_APP_VERSION) { meta?.setAttribute('content', REACT_APP_VERSION); }
};

addVersion();

Amplify.configure({
  Auth: {
    region: REACT_APP_AWS_REGION,
    userPoolId: REACT_APP_AWS_USERPOOLID,
    userPoolWebClientId: REACT_APP_AWS_USERPOOLWEBCLIENTID,
  },
});

const App = () => (
  <Router>
    <GlobalContextProvider>
      <Routes />
    </GlobalContextProvider>
  </Router>
);

export default App;
