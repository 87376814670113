import React, { useContext, useEffect } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { IntercomProvider } from 'react-use-intercom';

import Frame from '@root/components/Frame/Frame';
import { reportAnalytics, capitalize } from '@root/helpers/utils';
import { UserContext } from '@root/contexts/user.context';
import ProtectedRoute from '@root/routes/ProtectedRoute';
import Unauthorized from '@root/components//Unauthorized/Unauthorized';
import i18n from '@root/locales/i18n';
import Forbidden from '@customer/views/Forbidden/Forbidden';
import ProviderRoutes from '@customer/routes/providerRoutes';
import Providers from '@customer/views/Providers/Providers';
import SelectedPartners from '@customer/views/Providers/Partners/SelectedPartners';
import ConnectionRoutes from '@customer/routes/connectionRoutes';
import RedirectWithParams from '@customer/components/RedirectWithParams/RedirectWithParams';
import Proposal from '@customer/views/Proposal/Proposal';
import SurveyRoutes from '@customer/routes/surveyRoutes';
import ContactRoutes from '@customer/routes/contactRoutes';
import ApprovalRoutes from '@customer/routes/approvalRoutes';
import { Apps } from '@root/hooks/useCurrentApp';
import Enterprise from '@customer/views/Enterprise/Enterprise';
import { V1_LOGOUT } from '@root/helpers/constants.helper';
import ProfileSettings from '@root/views/ProfileSettings/ProfileSettings';
import ReportingIframe from '@customer/views/ReportingIframe/ReportingIframe';
import EnterpriseRoutes from '@customer/routes/enterpriseRoutes';
import NotFound from '@root/components/NotFound/NotFound';

const CustomerAppRoutes = () => {
  const location = useLocation();
  const { path } = useRouteMatch();
  const { user } = useContext(UserContext);

  i18n.setDefaultNamespace(capitalize(Apps.Customer));

  useEffect(() => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID && user.id) {
      reportAnalytics(user.id);
    }
  }, [location]);

  return (
    <I18nextProvider i18n={i18n}>
      <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID || ''}>
        <Switch>
          {/* v2 routes */}
          <ProtectedRoute exact path={path}>
            <Redirect to={`${path}/v1/dashboard`} />
          </ProtectedRoute>
          <Route path={`${path}/surveys`}>
            <SurveyRoutes />
          </Route>
          <Route path={`${path}/providers`}>
            <ProviderRoutes />
          </Route>
          <Route path={`${path}/connections`}>
            <ConnectionRoutes />
          </Route>
          <Route path={`${path}/contacts`}>
            <ContactRoutes />
          </Route>
          <Route path={`${path}/enterprises`}>
            <EnterpriseRoutes />
          </Route>
          <Route path={`${path}/forbidden`} exact>
            <Forbidden />
          </Route>
          <Route path={`${path}/not-found`} exact>
            <NotFound />
          </Route>
          <Route path={`${path}/approvals`}>
            <ApprovalRoutes />
          </Route>
          <ProtectedRoute path={`${path}/reporting`}>
            <ReportingIframe />
          </ProtectedRoute>
          <ProtectedRoute path={`${path}/enterprises/:enterpriseId`} exact>
            <Enterprise />
          </ProtectedRoute>
          <ProtectedRoute path={`${path}/profile/:info`}>
            <ProfileSettings />
          </ProtectedRoute>
          <ProtectedRoute path={`${path}/unauthorized`} exact>
            <Unauthorized />
          </ProtectedRoute>
          <ProtectedRoute path={`${path}/v1/enterprise/:enterpriseId/offer/:offerId/profile/create`} exact>
            <RedirectWithParams />
          </ProtectedRoute>
          <ProtectedRoute path={`${path}/offer/:offerId`} exact>
            <Providers
              isPunchoutActive
            />
          </ProtectedRoute>
          <ProtectedRoute path={`${path}/v1/sector/offer/:offerId/send-to-enterprise`} exact>
            <Providers
              isPunchoutActive
            />
          </ProtectedRoute>
          <ProtectedRoute path={`${path}/offer/:offerId/selected-partners`} exact>
            <SelectedPartners />
          </ProtectedRoute>
          <ProtectedRoute path={`${path}/v1/sector/offer/:offerId/response/:responseId`} exact>
            <Proposal />
          </ProtectedRoute>
          <ProtectedRoute path={`${path}/v1/*`} hasSidebar={!location.pathname.includes(V1_LOGOUT)}>
            <Frame title="v1" />
          </ProtectedRoute>
          <Route path="*">
            <Redirect to={`${path}/not-found`} />
          </Route>
        </Switch>
      </IntercomProvider>
    </I18nextProvider>
  );
};

export default CustomerAppRoutes;
