import React, { ReactNode } from 'react';
import {
  Col,
  Container,
  Row,
  Spinner,
} from 'react-bootstrap';

interface MainProps {
  title: React.ReactNode,
  titleSide?: ReactNode,
  children: ReactNode,
  className?: string,
  titleClassName?: string,
  titleSideCounter?: string,
  fullWidth?: boolean,
  isLoading?: boolean,
  noHeight?: boolean,
}

interface SideProps {
  title?: string,
  titleSide?: JSX.Element,
  children?: ReactNode,
  className?: string,
  noHeight?: boolean,
}

interface Props {
  children: ReactNode,
  isLoading?: boolean,
  className?: string,
  rowClassName?: string,
  noHeight?: boolean,
}

const AWContainer = ({
  isLoading,
  children,
  className,
  rowClassName,
  noHeight,
}: Props) => (
  <Container fluid className={`${!noHeight ? 'h-100' : ''} d-flex flex-column p-0 ${className}`}>
    <Row className={`${!noHeight ? 'h-100' : ''} g-0 flex-1 ${rowClassName}`}>
      {isLoading ? (
        <div className={`${!noHeight ? 'h-100' : ''} d-flex align-items-center justify-content-center`}>
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : children}
    </Row>
  </Container>
);

const Main = ({
  title,
  children,
  className,
  titleClassName,
  titleSide,
  titleSideCounter,
  fullWidth,
  isLoading,
  noHeight,
}: MainProps) => (
  <Col xl={fullWidth ? 12 : 8} className={`${!noHeight ? 'h-100' : ''} p-4 d-flex flex-column ${className}`}>
    <div className="d-flex justify-content-between align-items-center mb-4">
      <h1 className={`text-dark font-style-regular mb-0 ${titleClassName}`}>
        {title}
        {titleSideCounter ? (
          <sup className="aw-container__title-side-counter">
            &nbsp;
            (
            {titleSideCounter}
            )
          </sup>
        ) : ''}
      </h1>
      {titleSide || ''}
    </div>
    <div className="d-flex flex-column flex-grow-1 overflow-auto no-scrollbar">
      {isLoading ? (
        <div className={`${!noHeight ? 'h-100' : ''} d-flex align-items-center justify-content-center`}>
          <Spinner animation="border" variant="secondary" />
        </div>
      ) : children}
    </div>
  </Col>
);

const Side = ({
  title,
  children,
  className,
  titleSide,
  noHeight,
}: SideProps) => (
  <Col
    xl="4"
    className={`${!noHeight ? 'aw-container-side' : ''} d-flex flex-column bg-white p-4 order-first order-xl-last ${className}`}
  >
    <div className="d-flex justify-content-between">
      <h1 className="text-dark text-uppercase font-style-bold font-size-20 mt-2 mb-4">{title}</h1>
      {titleSide}
    </div>
    <div className="d-flex flex-column flex-grow-1 overflow-auto no-scrollbar">
      {children}
    </div>
  </Col>
);

AWContainer.Main = Main;
AWContainer.Side = Side;

AWContainer.defaultProps = {
  isLoading: false,
  className: '',
  rowClassName: '',
  noHeight: false,
};

Main.defaultProps = {
  className: '',
  titleClassName: '',
  titleSide: '',
  titleSideCounter: '',
  fullWidth: false,
  isLoading: false,
  noHeight: false,
};

Side.defaultProps = {
  title: '',
  titleSide: <> </>,
  className: '',
  children: <> </>,
  noHeight: false,
};

export default AWContainer;
