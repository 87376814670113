import React, { useMemo } from 'react';
import { compact } from 'lodash';
import { useTranslation } from 'react-i18next';
import LabelValue from '@customer/components/LabelValue/LabelValue';
import {
  formatSiret,
  numberToCurrency,
  toStringRange,
} from '@root/helpers/utils';
import { LegendreProvider } from '@customer/interfaces/legendre.interface';
import {
  EmployeeNumber,
  BusinessTurnover,
  Enterprise,
  ComplianceCount,
} from '@root/interfaces/enterprise.interface';
import { Partnership } from '@customer/interfaces/provider.interface';

interface Props {
  enterprise?: Enterprise & LegendreProvider,
  businessTurnover?: BusinessTurnover,
  employeeNumber?: EmployeeNumber,
  complianceCount?: ComplianceCount
  partnership?: Partnership
}

enum NumberValueType {
  Declarative = 'declarative',
  Official = 'official',
}

const EnterpriseGeneral = ({
  enterprise,
  businessTurnover,
  employeeNumber,
  complianceCount,
  partnership,
}: Props) => {
  const { t } = useTranslation();
  const address = useMemo((): string => {
    if (enterprise?.address) {
      return compact([
        enterprise.address.address,
        enterprise.address.additionnal_address,
      ]).join(' ');
    }
    return '';
  }, [enterprise?.address]);

  const addressCity = useMemo((): string => {
    if (enterprise?.address) {
      return compact([
        enterprise.address.zipcode,
        enterprise.address.town,
      ]).join(' ');
    }
    return '';
  }, [enterprise?.address]);

  const hasBusinessTurnover = (type: NumberValueType) => !!(
    businessTurnover && businessTurnover[`${type}_amount`]
  );

  const hasEmployeesNumber = (type: NumberValueType) => !!(
    employeeNumber && employeeNumber[`${type}_range_id`] && employeeNumber[`${type}_min`]
  );

  const identificationNumber = () => {
    if (enterprise?.country === 'be') {
      return t('Enterprise.identificationNumberBe', 'Numéro BCE');
    }
    if (enterprise?.country === 'de') {
      return t('Enterprise.identificationNumberDe', "Numéro d'enregistrement");
    }
    return t('Enterprise.identificationNumberFr', 'SIRET');
  };

  return (
    <div className="enterprise-info">
      <LabelValue keyName={t('Enterprise.name', "Nom d'enterprise")}>
        <span className="enterprise-name">{enterprise?.name}</span>
      </LabelValue>
      <LabelValue keyName={identificationNumber()}>
        {formatSiret(
          enterprise?.enterprise_identification_number
          || enterprise?.identification_number || '',
        )}
      </LabelValue>
      <LabelValue keyName={t('Enterprise.address', 'Adresse')}>
        {address}
        <br />
        {addressCity}
      </LabelValue>

      {
        enterprise?.main_activity_code ? (
          <LabelValue keyName={t('Enterprise.mainActivityCode', 'Code APE')}>
            {enterprise?.main_activity_code}
          </LabelValue>
        ) : ''
      }
      {
        enterprise?.tax_identification_number ? (
          <LabelValue keyName={t('Enterprise.vat', 'TVA')}>
            {enterprise?.tax_identification_number}
          </LabelValue>
        ) : ''
      }
      {
        enterprise?.registration_town ? (
          <LabelValue keyName={t('Enterprise.registrationTown', "Ville d'immatriculation")}>
            {enterprise?.registration_town}
          </LabelValue>
        ) : ''
      }
      <LabelValue keyName={t('BusinessTurnover.title')}>
        {hasBusinessTurnover(NumberValueType.Declarative) ? (
          `${numberToCurrency(businessTurnover!.declarative_amount!, businessTurnover!.currency || 'EUR')} (${businessTurnover!.year}) (${t('Data.declarative')})`
        ) : ''}
        {hasBusinessTurnover(NumberValueType.Declarative)
          && hasBusinessTurnover(NumberValueType.Official) ? <br /> : ''}
        {!hasBusinessTurnover(NumberValueType.Declarative)
          && !hasBusinessTurnover(NumberValueType.Official) ? '-' : ''}
        {hasBusinessTurnover(NumberValueType.Official) ? (
          `${numberToCurrency(businessTurnover!.official_amount!, businessTurnover!.currency || 'EUR')} (${businessTurnover!.year}) (${t('Data.official')})̀`
        ) : ''}
      </LabelValue>
      <LabelValue keyName={t('Enterprise.employeeNumber', "Nombre d'employés")}>
        {hasEmployeesNumber(NumberValueType.Declarative) ? (
          `${toStringRange(employeeNumber!.declarative_min!, employeeNumber!.declarative_max)} (${employeeNumber!.year}) (${t('Data.declarative')})`
        ) : ''}
        {hasEmployeesNumber(NumberValueType.Declarative)
          && hasEmployeesNumber(NumberValueType.Official) ? <br /> : ''}
        {!hasEmployeesNumber(NumberValueType.Declarative)
          && !hasEmployeesNumber(NumberValueType.Official) ? '-' : ''}
        {hasEmployeesNumber(NumberValueType.Official) ? (
          `${toStringRange(employeeNumber!.official_min!, employeeNumber!.official_max)} (${employeeNumber!.year}) (${t('Data.official')})`
        ) : ''}
      </LabelValue>
      {
        complianceCount ? (
          <LabelValue keyName={t('Enterprise.legalConformity')}>
            {complianceCount?.legal_total === complianceCount?.legal_validated
              ? t('CardEnterprise.ok', 'ok') : t('Status.inProgress')}
          </LabelValue>
        ) : ''
      }
      {
        complianceCount ? (
          <LabelValue keyName={t('Enterprise.extendedConformity')}>
            {complianceCount?.business_total === complianceCount?.business_validated
              ? t('CardEnterprise.ok', 'ok') : t('Status.inProgress')}
          </LabelValue>
        ) : ''
      }
      {
        partnership ? (
          <LabelValue keyName={t('Enterprise.status')}>
            {partnership?.activity_ends_at && new Date() > new Date(partnership?.activity_ends_at)
              ? t('Enterprise.inactive') : t('Enterprise.active')}
          </LabelValue>
        ) : null
      }
    </div>
  );
};

EnterpriseGeneral.defaultProps = {
  enterprise: null,
  businessTurnover: null,
  employeeNumber: null,
  complianceCount: null,
  partnership: null,
};

export default EnterpriseGeneral;
