import React, {
  createContext,
  ReactNode,
  FunctionComponent,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { debounce, cloneDeep } from 'lodash';
import {
  Address,
  Enterprise,
  EnterpriseFields,
  EnterprisePappers,
  Establishment,
  LegalForms,
  PappersResponse,
} from '@root/interfaces/enterprise.interface';
import { ApiResponse } from '@root/helpers/response-handler';
import { getEstablishments, LEGAL_FORMS_REFS, searchCompaniesFromPappers } from '@root/services/pappers.service';
import useSafeFetch from '@root/hooks/useSafeFetch';
import { getLegalFormsConfig } from '@root/api-configs/enterprise.api.config';
import usePrevious from '@root/hooks/usePrevious';
import useSafeState from '@root/hooks/useSafeState';
import { capitalize, getSiren } from '@root/helpers/utils';
import { FR_IDENTIFICATION_NUMBER_REGEX } from '@root/helpers/patterns';
import { AvailableCountries, CountryCode } from '@root/interfaces/utils.interface';
import useLoadingPromise from '@root/hooks/useLoadingPromise';

interface PropsProvider {
  children: ReactNode
}

interface OptionalEnterprise {
  id?: string;
  identification_number?: string;
  legal_form?: string;
  legal_form_id?: string;
  name?: string;
  address?: Address;
  is_vendor?: boolean;
  is_customer?: boolean;
  country?: CountryCode;
}

interface IEnterpriseCreationContext {
  isPappersError: boolean;
  enterprisePappers?: EnterprisePappers[];
  establishments?: Establishment[];
  enterprise: Enterprise;
  isManual: boolean;
  papperLoading: boolean;
  establishmentsLoading: boolean;
  legalForms: LegalForms;
  countries: Record<CountryCode, boolean>,
  setCountries: React.Dispatch<React.SetStateAction<Record<CountryCode, boolean>>>,
  setEnterprise: React.Dispatch<React.SetStateAction<Enterprise>>;
  setIsManual: React.Dispatch<React.SetStateAction<boolean>>;
  handleReset: () => void;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  searchEstablishments: (enterprise: Enterprise) => Promise<void>;
  resetEnterprise: (partialEnteprise?: OptionalEnterprise) => void;
  handleEnterpriseChange: (fieldName: string) => (e: { target: { value: string } }) => void;
  handleEstablishmentChange: (e: { target: { value: string } }) => void;
}

export const enterpriseBlank: Enterprise = {
  identification_number: '',
  legal_form: '',
  name: '',
  country: CountryCode.Fr,
  address: {
    address: '',
    additionnal_address: '',
    zipcode: '',
    town: '',
    country: CountryCode.Fr,
  },
  is_customer: false,
  is_vendor: true,
};

export const defaultCountries = AvailableCountries
  .reduce((acc, country) => ({ ...acc, [country]: true }), {}) as Record<CountryCode, boolean>;

export const EnterpriseCreationContext = createContext<IEnterpriseCreationContext>({
  isPappersError: false,
  enterprisePappers: undefined,
  establishments: undefined,
  enterprise: enterpriseBlank,
  isManual: false,
  papperLoading: false,
  establishmentsLoading: false,
  legalForms: { fr: [], de: [], be: [] },
  countries: defaultCountries,
  setCountries: () => {},
  setEnterprise: () => {},
  setIsManual: () => {},
  resetEnterprise: () => {},
  handleReset: () => {},
  setSearch: () => {},
  searchEstablishments: async () => {},
  handleEnterpriseChange: () => () => {},
  handleEstablishmentChange: () => {},
});

const EnterpriseCreationContextProvider: FunctionComponent<PropsProvider> = ({ children }) => {
  const [enterprise, setEnterprise] = useSafeState<Enterprise>(enterpriseBlank);
  const [isManual, setIsManual] = useSafeState(false);
  const [enterprisePappers, setEnterprisePappers] = useSafeState<EnterprisePappers[] | undefined>();
  const [establishments, setEstablishments] = useSafeState<Establishment[] | undefined>();
  const [isPappersError, setIsPappersError] = useSafeState(false);
  const [countries, setCountries] = useSafeState<Record<CountryCode, boolean>>(defaultCountries);

  const [search, setSearch] = useSafeState<string>();
  const prevSearch = usePrevious(search);

  const [initLegalForms, legalForms] = useSafeFetch<LegalForms>(
    getLegalFormsConfig,
    { fr: [], de: [], be: [] },
    (lf) => (
      lf.reduce((acc, obj) => {
        const key = obj.country;
        const curGroup = acc[key!] ?? [];
        return { ...acc, [key!]: [...curGroup, obj] };
      }, {})
    ),
  );
  const { waitWithLoad: waitPappers, isLoading: papperLoading } = useLoadingPromise();
  const {
    waitWithLoad: waitEstablishments,
    isLoading: establishmentsLoading,
  } = useLoadingPromise();

  const searchOnChange = useCallback(
    debounce(async (val) => {
      if (val && legalForms.fr) {
        const apiResponse: ApiResponse<EnterprisePappers[]> = await waitPappers(
          searchCompaniesFromPappers(
            val,
            legalForms?.fr,
          ),
        );
        if (apiResponse.success && apiResponse.data) {
          setEnterprisePappers(apiResponse.data);
        } else {
          setIsPappersError(true);
        }
      }
    }, 300),
    [legalForms?.fr],
  );

  useEffect(() => {
    initLegalForms({}, {
      fields: 'id,name,display_name,country',
      page_size: 100,
    });
  }, []);

  useEffect(() => {
    if (prevSearch !== search) {
      (async () => {
        await searchOnChange(search);
      })();
    }
  }, [search]);

  useEffect(() => {
    let legalForm;
    if (legalForms && enterprise) {
      const _legalForms = legalForms[enterprise.country || CountryCode.Fr];
      if (_legalForms && !isManual) {
        const specialFormat = LEGAL_FORMS_REFS.find(
          (lfr) => lfr.pappers === enterprise.legal_form,
        );
        if (specialFormat) {
          legalForm = _legalForms.find((lf) => lf.name === specialFormat.addworking);
        } else {
          legalForm = _legalForms.find(
            (lf) => lf.name === enterprise.legal_form?.split(',')[0]?.toLowerCase(),
          );
        }
      }
    } else {
      legalForm = { display_name: '', id: '' };
    }
    if (legalForm) {
      setEnterprise(
        {
          ...enterprise,
          legal_form_id: legalForm.id,
          origin_data: isManual ? 'manual' : 'api.pappers.fr',
        },
      );
    }
  }, [isManual, enterprise.legal_form, enterprise.country, legalForms]);

  useEffect(() => {
    setEnterprise({
      ...enterprise,
      origin_data: isManual ? 'manual' : 'api.pappers.fr',
    });
  }, [isManual]);

  const hasAddress = (establishment: Establishment): boolean => !!(
    establishment.adresse_ligne_1
    && establishment.code_postal
    && establishment.ville
  );

  const buildEnterprise = (
    establishment: Establishment,
    pappersRes?: PappersResponse,
  ): Enterprise => {
    const clone: Enterprise = cloneDeep(enterprise);
    clone.identification_number = establishment.siret;
    if (hasAddress(establishment)) {
      clone.address = {
        address: establishment.adresse_ligne_1 || '',
        additionnal_address: establishment.adresse_ligne_2 || '',
        zipcode: establishment.code_postal || '',
        town: establishment.ville || '',
        country: establishment.code_pays?.toLowerCase() || CountryCode.Fr,
      };
    }
    if (pappersRes) {
      clone.legal_form = pappersRes.forme_juridique;
      clone.name = pappersRes.nom_entreprise || pappersRes.denomination;
      clone.is_vendor = true;
      clone.is_customer = false;
      clone.registration_town = pappersRes.greffe;
      clone.main_activity_code = establishment.code_naf?.replace('.', '');
      clone.country = enterprise.country?.toLowerCase() as CountryCode || CountryCode.Fr;
      const formatedActivityDomain = pappersRes.domaine_activite?.split(' ; ')
        .map(capitalize)
        .join(', ');
      if (establishment.libelle_code_naf
        && pappersRes.domaine_activite?.includes(establishment.libelle_code_naf)) {
        clone.activity = formatedActivityDomain;
      } else {
        clone.activity = `${formatedActivityDomain}, ${establishment.libelle_code_naf}`;
      }
    }
    return clone;
  };

  const resetEnterprise = (partialEnteprise: OptionalEnterprise = {}) => {
    setEnterprise({
      ...enterpriseBlank,
      ...partialEnteprise,
    });
    setEnterprisePappers(undefined);
  };

  const searchEstablishments = async (_enterprise: Enterprise) => {
    setIsPappersError(false);
    if (
      !isManual && (!establishments?.length || (
        establishments[0]
        && (
          getSiren(establishments[0].siret) !== getSiren(_enterprise.identification_number || '')
        )
      ))
    ) {
      if (_enterprise.identification_number) {
        const response = await waitEstablishments(
          getEstablishments(getSiren(_enterprise.identification_number)),
        );
        if (
          response.success
          && response.data?.etablissements
          && Array.isArray(response.data.etablissements)
          && response.data.etablissements.length
        ) {
          const activeEstablishments = response.data.etablissements.filter(
            (es) => !es.date_cessation,
          );
          if (activeEstablishments.length) {
            setEstablishments(activeEstablishments as Establishment[]);
            let establishment = buildEnterprise(activeEstablishments[0], response.data);
            if (FR_IDENTIFICATION_NUMBER_REGEX.test(search.trim())) {
              const establishmentFound = activeEstablishments.find(
                (e) => e.siret === search.trim(),
              );
              if (establishmentFound) {
                establishment = buildEnterprise(establishmentFound, response.data);
              }
            }
            setEnterprise(establishment);
          } else {
            setEnterprise({
              ...enterpriseBlank,
              country: enterprise.country,
              name: response.data.nom_entreprise,
            });
            setEstablishments([]);
          }
        }
      }
    }
  };

  const handleReset = () => {
    resetEnterprise({ country: CountryCode.Fr });
    setIsManual(false);
    setEnterprisePappers(undefined);
  };

  const handleEnterpriseChange = (fieldName: string) => (
    { target: { value } }: { target: { value: string } },
  ) => {
    if (fieldName.includes('.')) {
      const fields = fieldName.split('.');
      setEnterprise({
        ...enterprise,
        [fields[0]]: {
          ...enterprise[fields[0]],
          [fields[1]]: value,
        },
      });
    } else if (legalForms && fieldName === EnterpriseFields.LegalFormId) {
      const legalForm = legalForms[enterprise.country || CountryCode.Fr]?.find((lf) => (
        lf.id === value
      ));
      setEnterprise({
        ...enterprise,
        legal_form: legalForm?.display_name || '',
        legal_form_id: value,
      });
    } else {
      setEnterprise({ ...enterprise, [fieldName]: value });
    }
  };

  const handleEstablishmentChange = (e) => {
    const establishment = establishments?.find((et: Establishment) => (
      et.siret === e.target.value
    ));
    if (establishment) {
      setEnterprise(buildEnterprise(establishment));
    }
  };

  const value = useMemo(() => ({
    enterprise,
    isManual,
    isPappersError,
    enterprisePappers,
    establishments,
    legalForms,
    papperLoading,
    establishmentsLoading,
    countries,
    setCountries,
    setEnterprise,
    setIsManual,
    resetEnterprise,
    handleReset,
    setSearch,
    searchEstablishments,
    handleEnterpriseChange,
    handleEstablishmentChange,
  }), [
    enterprise,
    isManual,
    isPappersError,
    enterprisePappers,
    establishments,
    legalForms,
    papperLoading,
    establishmentsLoading,
    countries,
  ]);
  return (
    <EnterpriseCreationContext.Provider
      value={value}
    >
      {children}
    </EnterpriseCreationContext.Provider>
  );
};

export default EnterpriseCreationContextProvider;
