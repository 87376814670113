/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';

import { UserContext } from '@root/contexts/user.context';
import useLoadingPromise from '@root/hooks/useLoadingPromise';
import { Field } from '@root/interfaces/field.interface';
import {
  EmployeeNumber,
  BusinessTurnover,
  Department,
  Activity,
  Enterprise,
} from '@root/interfaces/enterprise.interface';

import AWButton from '@root/components/AWButtons/AWButton';
import ReturnButton from '@root/components/ReturnButton/ReturnButton';
import {
  BusinessAmount,
  ComplianceCount,
  Partnership,
  ContractCount,
  Certification,
} from '@customer/interfaces/provider.interface';
import addToCart from '@root/assets/add-to-cart.svg';
import removeFromCart from '@root/assets/remove-from-cart.svg';
import ModalPunchOut from '@customer/components/ModalPunchOut/ModalPunchOut';
import { AlertContext } from '@root/contexts/alert.context';
import {
  createFieldData,
  getPartners,
} from '@customer/services/provider.service';
import {
} from '@customer/services/enterprise.service';
import {
  getMissionOffer, getMissionProviders, addToCart as addToCartService,
  deleteFromCart as deleteFromCartService,
} from '@customer/services/mission.service';
import { MissionOfferProvider, MissionProvider } from '@customer/interfaces/mission.interface';
import { Mission as IMission } from '@root/interfaces/mission.interface';
import { getHigherObjectBy } from '@root/helpers/utils';
import { Option } from '@root/interfaces/utils.interface';
import useSafeFetch, { useSafeFetchCallback } from '@root/hooks/useSafeFetch';
import {
  getBusinessAmountConfig,
  getBusinessTurnoversConfig,
  getContractsBetweenEnterprisesConfig,
  getCustomerFieldsConfig,
  getEmployeeNumbersConfig,
  getEnterpriseActivitiesConfig,
  getEnterpriseDepartmentsConfig,
  getPartnershipConfig,
  getProviderFieldsConfig,
  getProviderCertificationsConfig,
  getEnterpriseContactsConfig,
} from '@root/api-configs/enterprise.api.config';
import useSafeState from '@root/hooks/useSafeState';
import usePrevious from '@root/hooks/usePrevious';
import { getComplianceCountConfig } from '@root/api-configs/compliance.api.config';
import { enterpriseTabsView, fetchEnterprise } from '@root/helpers/enterprise.helper';
import AWContainer from '@root/components/AWContainer/AWContainer';
import BurgerMenu from '@root/components/BurgerMenu/BurgerMenu';
import Tabs from '@customer/components/Tabs/Tabs';
import General from '@customer/views/Providers/Provider/General';
import { BurgerMenu as IBurgerMenu } from '@root/interfaces/menu.interface';
import EnterpriseContact from '@root/components/Enterprise/EnterpriseContact';
import { CUSTOMER_BASE_PATH } from '@root/helpers/constants.helper';
import { Contact } from '@root/interfaces/contact.interface';
import { getConnectionsByEnterpriseIdConfig } from '@root/api-configs/connection.api.config';
import { Connection } from '@root/interfaces/connection.interface';

interface Props {
  enterpriseId?: string;
}

const Provider = ({ enterpriseId }: Props) => {
  const { t } = useTranslation();
  const { user, tokenPO } = useContext(UserContext);
  const { enterpriseId: _enterpriseId, offerId } = useParams<{
    enterpriseId: string,
    offerId: string,
  }>();
  const { replace } = useHistory();
  const { waitWithLoad, isLoading } = useLoadingPromise();
  const prevCustomerId = usePrevious(user.currentEnterprise?.id);
  const { setNotif } = useContext(AlertContext);

  const [activeTab, setActiveTab] = useState(enterpriseTabsView[0]);

  // PUNCHOUT STATES
  const [providersInBasket, setProvidersInBasket] = useSafeState<MissionProvider[]>();
  const [mission, setMission] = useSafeState<IMission>();
  const [showPunchOutModal, setShowPunchOutModal] = useSafeState<boolean>(false);

  // PROVIDER
  const [provider, setProvider] = useSafeState<Enterprise>();

  // API STATES
  const {
    init: initFields,
    data: fields,
    setData: setFields,
  } = useSafeFetch<Field[]>(getProviderFieldsConfig, []).toObject;
  const [
    initCustomerFields,
    customerFields,
  ] = useSafeFetch<Field[]>(getCustomerFieldsConfig, []);
  const [
    initBusinessTurnover,
    businessTurnover,
  ] = useSafeFetch<BusinessTurnover>(
    getBusinessTurnoversConfig,
    undefined,
    (turnovers: BusinessTurnover[]) => getHigherObjectBy('year', turnovers),
  );
  const [
    initCertifications,
    certifications,
  ] = useSafeFetch<Certification[]>(getProviderCertificationsConfig, []);
  const [
    initEmployeeNumber,
    employeeNumber,
  ] = useSafeFetch<EmployeeNumber>(
    getEmployeeNumbersConfig,
    undefined,
    (_employees: EmployeeNumber[]) => getHigherObjectBy('year', _employees),
  );
  const [
    initComplianceCount,
    complianceCount,
  ] = useSafeFetch<ComplianceCount>(getComplianceCountConfig, undefined);
  const [
    initContractsCount,
    contractsCount,
  ] = useSafeFetch<ContractCount>(getContractsBetweenEnterprisesConfig);
  const [
    initBusinessAmount,
    businessAmount,
  ] = useSafeFetch<BusinessAmount>(getBusinessAmountConfig);
  const [initPartnership, partnership] = useSafeFetch<Partnership>(getPartnershipConfig, undefined);
  const [initActivities, activities] = useSafeFetch<Activity[]>(getEnterpriseActivitiesConfig, []);
  const [
    initDepartments,
    departments,
  ] = useSafeFetch<Department[]>(getEnterpriseDepartmentsConfig, []);
  const [initContacts, contacts] = useSafeFetch<Contact[]>(getEnterpriseContactsConfig, []);
  const [
    initConnections,
    connections,
  ] = useSafeFetch<Connection[]>(getConnectionsByEnterpriseIdConfig, []);

  const getEnterpriseService = useSafeFetchCallback(fetchEnterprise);

  const identifier = useMemo(() => (
    enterpriseId || _enterpriseId
  ), [enterpriseId, _enterpriseId]);

  const connection = useMemo(() => (
    connections.find(
      (c) => c.customer_id === user.currentEnterprise?.id && c.provider_id === identifier,
    )
  ), [connections]);

  const burgerMenu: IBurgerMenu = useMemo(() => {
    let options = [
      {
        label: t('ProviderBurgerMenu.seeDocuments', 'Voir les documents'),
        linkTo: `${CUSTOMER_BASE_PATH}/v1/addworking/enterprise/${identifier}/document#nav-documents-legaux-tab`,
      },
      {
        label: t('ProviderBurgerMenu.seeContracts', 'Voir les contrats'),
        linkTo: `${CUSTOMER_BASE_PATH}/v1/contract?filter[parties]=${identifier}`,
      },
      {
        label: t('ProviderBurgerMenu.seeBills'),
        linkTo: `${CUSTOMER_BASE_PATH}/v1/inbound-invoice?filter[vendors][]=${identifier}`,
      },
      {
        label: t('ProviderBurgerMenu.seeEvaluations', 'Voir les évaluations'),
        linkTo: `${CUSTOMER_BASE_PATH}/v1/enterprise/${identifier}/evaluations`,
      },
    ];

    if (connection?.id) {
      options = [
        ...options,
        {
          label: t('ProviderBurgerMenu.seeConnection', 'Voir la mise en relation'),
          linkTo: `${CUSTOMER_BASE_PATH}/connections/${connection.id}`,
        }];
    }
    return {
      sections: [{ options }],
    };
  }, [identifier, connection]);

  const fetchData = async () => {
    if (user.currentEnterprise?.id && prevCustomerId !== user.currentEnterprise.id) {
      const partnersRes = await waitWithLoad(getPartners(
        user.currentEnterprise.id,
        {
          fields: ['id', 'vendor_id'],
          search: {
            vendor_id: identifier,
          },
        },
      ));
      if (partnersRes.success && partnersRes.data.length) {
        const _provider = await waitWithLoad(getEnterpriseService(identifier));
        setProvider(_provider);
        if (_provider?.id) {
          await Promise.all([
            initActivities({ id: _provider.id }, { fields: 'activity' }),
            initFields({ providerId: _provider.id }),
            initCustomerFields({ customerId: user.currentEnterprise.id }),
            initDepartments(
              { identificationNumber: _provider.identification_number },
              { fields: 'insee_code,display_name', page_size: 150 },
            ),
            initPartnership({
              providerId: _provider.id,
              customerId: user.currentEnterprise.id,
            }),
            initComplianceCount({
              providerId: _provider.id,
              query: { customer_id: user.currentEnterprise.id },
            }),
            initContractsCount({ providerId: _provider.id, customerId: user.currentEnterprise.id }),
            initCertifications({ providerId: _provider.id }, { fields: 'label' }),
            initBusinessAmount({ providerId: _provider.id, customerId: user.currentEnterprise.id }),
            initBusinessTurnover(
              { enterpriseId: _provider.id },
              { fields: 'year,declarative_amount,official_amount' },
            ),
            initEmployeeNumber(
              { enterpriseId: _provider.id },
              {
                fields: [
                  'year',
                  'declarative_range_id',
                  'official_range_id',
                  'declarative_min',
                  'official_min',
                  'declarative_max',
                  'official_max',
                ],
              },
            ),
            initContacts({}, {
              fields: ['id', 'first_name', 'last_name', 'label', 'email', 'phone_number'],
              search: {
                provider_id: _provider.id,
                customer_id: user.currentEnterprise.id,
              },
            }),
            initConnections({ enterpriseId: user.currentEnterprise.id }),
          ]);
        }
      } else {
        replace(CUSTOMER_BASE_PATH);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [user.currentEnterprise?.id]);

  const handleModal = (isOpen: boolean) => () => {
    setShowPunchOutModal(isOpen);
  };

  const handleFieldChange = (fieldId: string) => async (values: Option[] | Option) => {
    let contentIds: string[] = [];
    if (Array.isArray(values)) {
      contentIds = values.reduce((acc: string[], { value }) => {
        if (value?.length) {
          acc.push(value);
        }
        return acc;
      }, []);
    } else if (values?.value?.length) {
      contentIds.push(values.value);
    }
    if (fieldId && provider?.id) {
      const createFieldRes = await createFieldData(
        fieldId,
        {
          provider_id: provider.id,
          content_ids: contentIds,
        },
      );
      if (createFieldRes.success) {
        setNotif(t('Provider.fieldchangedsuccessfully', 'Votre demande a bien été enregistrée'));
        if (createFieldRes?.data) {
          const clone = cloneDeep(fields);
          setFields([...clone.filter((f) => f.id !== fieldId), ...createFieldRes.data]);
        }
      }
    }
  };

  const getMission = async (id) => {
    const missionRes = await getMissionOffer(id, {
      fields: 'id,starts_at_desired,ends_at,status,label,description',
    });
    if (missionRes.success) {
      if (missionRes?.data) {
        setMission(missionRes.data);
      }
    }
  };

  const getProviderInBasket = async (id) => {
    const providerInBasketRes = await getMissionProviders(id, {
      fields: 'id,display_name,status,vendor_enterprise_id,name,identification_number',
      search: {
        status: MissionOfferProvider.InBasket,
      },
    });
    if (providerInBasketRes.success && providerInBasketRes.data) {
      setProvidersInBasket(providerInBasketRes.data);
    }
  };

  const addToCard = async (providerId) => {
    const body = {
      vendor_enterprise_id: providerId,
      status: 'inBasket',
      label: mission?.label,
      details: mission?.description,
      valid_from: mission?.starts_at_desired,
      valid_until: mission?.ends_at,
      mission_offer_id: mission?.id,
    };
    const addToCartRes = await addToCartService(offerId, body);
    if (addToCartRes.hasError()) {
      setNotif({
        message: t(
          'SelectedPartner.errorAddingToCart',
          "Ce prestataire n'a pas pu être ajouté à votre sélection",
        ),
        variant: 'danger',
      });
    } else {
      setNotif(
        t('SelectedPartner.addedToCart', 'Ce prestataire a bien été ajouté à votre sélection'),
      );
      getProviderInBasket(offerId);
    }
  };

  const handleDelete = async (offerMissionId, providerId) => {
    const deleteRes = await deleteFromCartService(offerMissionId, providerId);
    if (deleteRes.success) {
      setNotif(
        t('SelectedPartner.addedToCart', 'Ce prestataire a bien été retiré de votre sélection'),
      );
      getProviderInBasket(offerId);
    } else {
      setNotif({
        message: t(
          'SelectedPartner.removeError',
          "Ce prestataire n'a pas pu être retiré de votre sélection",
        ),
        variant: 'danger',
      });
    }
  };

  useEffect(() => {
    if (offerId) {
      getMission(offerId);
      getProviderInBasket(offerId);
    }
  }, [offerId]);

  const mainTitleSide = () => {
    if (!offerId) return <BurgerMenu sections={burgerMenu.sections} />;
    if (offerId) {
      if (providersInBasket?.find((missionOffer) => missionOffer.vendor_enterprise_id === provider?.id)?.status === 'inBasket') {
        return (
          <AWButton className="mb-3" backgroundColor="#B6B6B6" onClick={() => handleDelete(offerId, provider?.id)}>
            <img src={removeFromCart} alt="remove from cart" className="add-cart" />
          </AWButton>
        );
      }
      if (providersInBasket?.find((missionOffer) => missionOffer.vendor_enterprise_id === provider?.id)?.status !== 'inBasket') {
        return (
          <AWButton className="mb-3" onClick={() => addToCard(provider?.id)}>
            <img src={addToCart} alt="add to cart" className="add-cart" />
          </AWButton>
        );
      }
    }
    return null;
  };

  const mainContent = () => {
    switch (activeTab.key) {
      case 'info':
        return (
          <General
            enterprise={provider}
            businessTurnover={businessTurnover}
            complianceCount={complianceCount}
            employeeNumber={employeeNumber}
            departments={departments}
            activities={activities}
            certifications={certifications}
            partnership={partnership}
            fields={fields}
            customerFields={customerFields}
            handleFieldChange={handleFieldChange}
          />
        );

      default:
        return (
          <EnterpriseContact
            representatives={provider.representatives || []}
            phoneNumbers={provider.phone_numbers || []}
            contacts={contacts}
            connection={connection || {}}
          />
        );
    }
  };

  return (
    <>
      {
        provider ? (
          <ModalPunchOut
            show={showPunchOutModal}
            onCancel={handleModal(false)}
            provider={provider}
          />
        ) : null
      }
      <AWContainer isLoading={isLoading}>
        <AWContainer.Main
          title={(
            <div className="d-flex">
              <ReturnButton />
              <span>{`${provider?.legal_form} ${provider?.name}`}</span>
            </div>
          )}
          titleSide={mainTitleSide()}
        >
          <Tabs
            tabs={enterpriseTabsView}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          {mainContent()}
        </AWContainer.Main>
        <AWContainer.Side
          titleSide={(
            <div className="flex-1 d-flex justify-content-end">
              <AWButton
                onClick={handleModal(true)}
                className={!tokenPO ? 'invisible' : ''}
              >
                {t('Provider.chooseProvider', 'Choisir ce sous-traitant')}
              </AWButton>
            </div>
          )}
        />
      </AWContainer>
      {/* TODO: DETERMINE WHERE TO DISPLAY PROVIDER DEALS
        <ProviderDeal
          partnership={partnership}
          complianceCount={complianceCount}
          contractsCount={contractsCount}
          businessAmount={businessAmount}
          providerId={provider?.id}
        />
      */}
    </>
  );
};

Provider.defaultProps = {
  enterpriseId: '',
};

export default Provider;
