import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';
import { Form } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import { UserContext } from '@root/contexts/user.context';
import {
  ProviderFilters,
  ProvidersLoadingBtn,
} from '@customer/interfaces/provider.interface';
import ProvidersFilters from '@customer/views/Providers/ProvidersFilters/ProvidersFilters';
import AWContainer from '@root/components/AWContainer/AWContainer';
import { AlertContext } from '@root/contexts/alert.context';
import { Option } from '@root/interfaces/utils.interface';
import { MissionOfferProvider, MissionProvider } from '@customer/interfaces/mission.interface';
import { Mission as IMission } from '@root/interfaces/mission.interface';
import AWButton from '@root/components/AWButtons/AWButton';
import addToCartSVG from '@root/assets/add-to-cart.svg';
import removeFromCartSVG from '@root/assets/remove-from-cart.svg';
import CardEnterprise from '@root/components/Cards/CardEnterprise/CardEnterprise';
import useSafeFetch from '@root/hooks/useSafeFetch';
import { CUSTOMER } from '@root/helpers/constants.helper';
import { ActivePartner } from '@root/interfaces/enterprise.interface';
import { getEnterpriseActivePartnersConfig } from '@root/api-configs/enterprise.api.config';
import { enterprisesPageSize } from '@root/helpers/enterprise.helper';
import {
  addToCartConfig,
  deleteFromCartConfig,
  getMissionOfferConfig,
  getMissionProvidersConfig,
} from '@root/api-configs/mission.api.config';

interface Props {
  isPunchoutActive?: boolean,
  isFiltersActive?: boolean
}

const SESSION_EXPIRED = 'SESSION_EXPIRED';

const Providers = ({
  isPunchoutActive,
  isFiltersActive,
}: Props) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const history = useHistory();
  const { setNotif } = useContext(AlertContext);
  const { offerId } = useParams<{ offerId: string }>();

  const [page, setPage] = useState(1);
  const [hasMorePages, setHasMorePages] = useState(false);
  const [filters, setFilters] = useState<ProviderFilters>({});
  const [loadingBtn, setLoadingBtn] = useState<ProvidersLoadingBtn>();
  const [search, setSearch] = useState<string>('');

  const {
    data: providers,
    setData: setProviders,
    callApi: fetchProviders,
    loading,
  } = useSafeFetch<ActivePartner[]>(getEnterpriseActivePartnersConfig, []).toObject;

  const [
    initMission,
    mission,
  ] = useSafeFetch<IMission>(getMissionOfferConfig);

  const [
    initProvidersInBasket,
    providersInBasket,
  ] = useSafeFetch<MissionProvider[]>(getMissionProvidersConfig, []);

  const addToCartService = useSafeFetch(addToCartConfig).callApi;
  const deleteFromCartService = useSafeFetch(deleteFromCartConfig).callApi;

  const getProviders = async (_page = page, _search = '') => {
    const transformFields = (fields: Record<string, Option[]> = {}) => Object.keys(fields)
      .flatMap((contentId) => (
        fields[contentId].reduce((acc: string[], { value }) => {
          if (value?.length && contentId?.length) {
            acc.push(`${contentId}:${value}`);
          }
          return acc;
        }, [])
      ))
      .join(',');
    const res = await fetchProviders({
      enterpriseId: user.currentEnterprise?.id,
      search: new URLSearchParams({
        page: _page.toString(),
        type: CUSTOMER,
        search: filters.search || _search,
        departments: filters.departments?.join(',') || '',
        certifications: filters.certifications?.join(',') || '',
        fields: transformFields(filters.fields),
        activities: filters.activities?.join(',') || '',
        businessTurnoverMin: filters.businessTurnoverMin || '',
        businessTurnoverMax: filters.businessTurnoverMax || '',
      }).toString(),
    });
    if (res?.success) {
      setProviders(page === 1 ? res.data : [...providers, ...res.data]);
      setHasMorePages(res.data?.length === enterprisesPageSize);
    } else if (
      !Array.isArray(res?.data)
      && (res?.data as any)?.details?.code === SESSION_EXPIRED
    ) {
      setNotif(t('Punchout.expired', 'Votre session est expirée'));
    }
  };

  const fetchProvidersInBasket = () => {
    initProvidersInBasket(
      { offerId },
      {
        fields: 'id,display_name,status,vendor_enterprise_id,name,identification_number',
        search: {
          status: MissionOfferProvider.InBasket,
        },
      },
    );
  };

  useEffect(() => {
    if (offerId) {
      initMission(
        { offerId },
        { fields: 'id,starts_at_desired,ends_at,status,label,description' },
      );
      fetchProvidersInBasket();
    }
  }, [offerId]);

  useEffect(() => {
    getProviders();
  }, [page, user.currentEnterprise?.id]);

  const addToCart = async (providerId) => {
    const body = {
      vendor_enterprise_id: providerId,
      status: MissionOfferProvider.InBasket,
      label: mission?.label,
      details: mission?.description,
      valid_from: mission?.starts_at_desired,
      valid_until: mission?.ends_at,
      mission_offer_id: mission?.id,
    };
    const addToCartRes = await addToCartService({
      offerId,
      body,
    });
    if (addToCartRes && addToCartRes.hasError()) {
      setNotif(t('SelectedPartner.errorAddingToCart', "Ce prestataire n'a pas pu être ajouté à votre sélection"));
    } else {
      setNotif(t('SelectedPartner.addedToCart', 'Ce prestataire a bien été ajouté à votre sélection'));
      fetchProvidersInBasket();
    }
  };

  const handleDelete = async (offerMissionId, providerId) => {
    const deleteRes = await deleteFromCartService({ offerId: offerMissionId, providerId });
    if (deleteRes && deleteRes.success) {
      setNotif(t('SelectedPartner.removedFromCart', 'Ce prestataire a bien été retiré de votre sélection'));
      fetchProvidersInBasket();
    } else {
      setNotif(t('SelectedPartner.removeError', "Ce prestataire n'a pas pu être retiré de votre sélection"));
    }
  };

  const isInBasket = (providerId) => (
    providersInBasket?.find((el) => el.vendor_enterprise_id === providerId)
  );

  const goToRecap = () => {
    history.push(`/customer/offer/${offerId}/selected-partners`);
  };

  const handleFiltersChange = (fieldName: keyof ProviderFilters) => (e) => {
    setFilters({ ...filters, [fieldName]: e.target?.value || e.value });
  };

  const handleFiltersArrayChange = (fieldName: keyof ProviderFilters, value: string[]) => {
    setFilters({ ...filters, [fieldName]: value });
  };

  const handleCustomFieldChange = (id: string, values: Option[]) => {
    setFilters({ ...filters, fields: { ...filters.fields, [id]: values } });
  };

  const isBtnLoading = (btn: ProvidersLoadingBtn) => (loading && loadingBtn === btn);

  const handleFilters = (_search?: string) => {
    if (page !== 1) {
      setPage(1);
    } else {
      getProviders(1, _search);
    }
  };

  const debouncedFiltersSearch = useCallback(
    debounce((val) => {
      handleFilters(val);
    }, 300),
    [],
  );

  const handleSearch = (e) => {
    const value = e.target?.value;
    setSearch(value);
    debouncedFiltersSearch(value);
  };

  const loadMore = () => {
    setLoadingBtn(ProvidersLoadingBtn.MORE);
    setPage((p) => p + 1);
  };

  return (
    <AWContainer
      isLoading={
        loading
        && !providers.length
        && !Object.keys(filters).length
      }
    >
      <AWContainer.Main
        title={(
          <div className="d-flex align-items-center">
            <span>
              {isPunchoutActive
                ? t('MissionOffers.title', "Sélection des prestataires pour l'offre de mission {{mission}}", { mission: `'${mission?.label}'` || '' })
                : t('Providers.title', 'Mes sous-traitants')}
            </span>
            <Form onSubmit={(e) => e.preventDefault()} className="ms-4 providers-searchbar">
              <Form.Group>
                <Form.Control
                  id="enterpriseIdentity"
                  onChange={handleSearch}
                  value={search}
                  size="sm"
                />
              </Form.Group>
            </Form>
          </div>
        )}
      >
        {
          providers.map((provider) => (
            <div
              key={provider.identification_number}
              className="d-flex justify-content-between"
            >
              <CardEnterprise
                enterpriseType="provider"
                enterprise={provider}
                onClick={() => history.push(`/customer/providers/${provider.provider_id}/${offerId || ''}`)}
              />
              {
                offerId && mission ? (
                  <AWButton
                    className="mb-3"
                    backgroundColor="#B6B6B6"
                    onClick={() => ((isInBasket(provider?.provider_id))
                      ? handleDelete(offerId, provider?.provider_id)
                      : addToCart(provider.provider_id)
                    )}
                  >
                    <img
                      src={isInBasket(provider?.provider_id) ? removeFromCartSVG : addToCartSVG}
                      alt="add to cart"
                      className="add-cart"
                    />
                  </AWButton>
                ) : ''
              }
            </div>
          ))
        }
        {
          hasMorePages ? (
            <AWButton
              type="button"
              className="rounded"
              backgroundColor="white"
              color="black"
              loaderColor="secondary"
              onClick={loadMore}
              isLoading={isBtnLoading(ProvidersLoadingBtn.MORE)}
            >
              {t('Providers.displayMoreProviders', 'Afficher plus de sous-traitants')}
            </AWButton>
          ) : null
        }
      </AWContainer.Main>
      <AWContainer.Side>
        {
          offerId && mission
            ? (
              <div className="selected-partners__container">
                <p className="selected-partners">
                  <span className="selected-partners__number">
                    {providersInBasket?.length || 0}
                  </span>
                  {` ${t(
                    'MissionOffers.selectedProviders',
                    'prestataire{{s}} sélectionné{{s}}',
                    { s: providersInBasket?.length > 1 ? 's' : '' },
                  )}`}
                </p>
                <AWButton type="button" onClick={goToRecap} className="w-50">
                  {t('MissionOffers.mySelection', 'Ma sélection')}
                </AWButton>
              </div>
            ) : ''
        }
        {
          isFiltersActive
            ? (
              <ProvidersFilters
                customerId={user.currentEnterprise?.id}
                handleFilters={handleFilters}
                isBtnLoading={isBtnLoading}
                handleLoadingBtn={setLoadingBtn}
                onChange={handleFiltersChange}
                onArrayChange={handleFiltersArrayChange}
                onCustomFieldsChange={handleCustomFieldChange}
              />
            ) : ''
        }

      </AWContainer.Side>
    </AWContainer>
  );
};

Providers.defaultProps = {
  isPunchoutActive: false,
  isFiltersActive: true,
};

export default Providers;
