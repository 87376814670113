/* eslint-disable react/jsx-props-no-spreading, @typescript-eslint/no-unused-vars */
import React, {
  useEffect,
  useContext,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Col,
  Row,
  Spinner,
} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import InformationSVG from '@provider/components/Icons/InformationSVG';
import ComplianceSVG from '@provider/components/Icons/ComplianceSVG';
import JobSVG from '@provider/components/Icons/JobSVG';
import AWMiniCard from '@provider/components/AWMiniCard/AWMiniCard';
import CircularProgress from '@provider/components/CircularProgress/CircularProgress';
import AWMicroCard from '@provider/components/AWMicroCard/AWMicroCard';
import {
  EnterpriseCompletion, ComplianceCount, SkillCount,
} from '@root/interfaces/enterprise.interface';
import { UserContext } from '@root/contexts/user.context';
import {
  Connection,
  ConnectionStatusToInt,
} from '@root/interfaces/connection.interface';
import { AWColors } from '@root/interfaces/utils.interface';
import useSafeFetch from '@root/hooks/useSafeFetch';
import {
  getComplianceCountConfig,
} from '@root/api-configs/compliance.api.config';
import {
  getCompletionConfig,
  getCustomersCountConfig,
} from '@root/api-configs/enterprise.api.config';
import { getConnectionsConfig } from '@root/api-configs/connection.api.config';
import { Mission } from '@root/interfaces/mission.interface';
import { getMissionFromEnterpriseIdConfig } from '@root/api-configs/mission.api.config';
import { Contract } from '@root/interfaces/contract.interface';
import { getContractsConfig } from '@root/api-configs/contracts.api.config';
import { InboundInvoice } from '@root/interfaces/invoice.interface';
import { getInboundInvoicesConfig } from '@root/api-configs/invoice.api.config';
import useCurrentApp from '@root/hooks/useCurrentApp';

const Dashboard = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { push } = useHistory();
  const { app } = useCurrentApp();

  const [initConnections, connections] = useSafeFetch<Connection[]>(getConnectionsConfig, []);
  const [initComplianceCount, complianceCount, loadingCompliance] = useSafeFetch<ComplianceCount>(
    getComplianceCountConfig,
  );
  const [
    initCustomersCount,
    customersCount,
  ] = useSafeFetch<number>(getCustomersCountConfig, 0, 'customers');
  const [
    initCompletionCount,
    completionCount,
    loadingCompletion,
  ] = useSafeFetch<EnterpriseCompletion>(getCompletionConfig);
  const [initMissions, missions] = useSafeFetch<Mission[]>(getMissionFromEnterpriseIdConfig, []);
  const [initContracts, contracts] = useSafeFetch<Contract[]>(getContractsConfig, []);
  const [initInvoices, invoices] = useSafeFetch<InboundInvoice[]>(getInboundInvoicesConfig, []);

  const getColor = (percentage: number) => {
    if (!percentage) return AWColors.Red;
    if (percentage === 100) {
      return AWColors.Green;
    }
    return AWColors.Orange;
  };

  const getPercentage = (total: number, part: number): number => (
    typeof part === 'number' && total > 0 && total >= part
      ? (Math.round((part / total) * 100) || 0) : 0
  );

  const compliance = useMemo<{ legal: number, business: number }>(() => {
    let legal = 0;
    let business = 0;
    if (complianceCount?.provider_id) {
      legal = getPercentage(complianceCount.legal_total, complianceCount.legal_validated);
      if (typeof customersCount === 'string' && parseInt(customersCount, 10) === 0) {
        business = NaN;
      } else if (typeof customersCount === 'string') {
        business = complianceCount.business_total === 0 && complianceCount.business_validated === 0
          ? 100
          : getPercentage(complianceCount.business_total, complianceCount.business_validated);
      }
    }
    return { legal, business };
  }, [complianceCount, customersCount]);

  const allCompliance = useMemo<number>(() => (
    complianceCount?.provider_id ? (
      getPercentage(
        complianceCount.legal_total + complianceCount.business_total,
        complianceCount.legal_validated + complianceCount.business_validated,
      )
    ) : 0
  ), [compliance]);

  const completion = useMemo(() => {
    if (completionCount) {
      const sum = Object.values(completionCount)
        .filter((v: number | string) => typeof v === 'number' && v > 0)
        .reduce((acc: number, v: number) => (acc + v), 0);
      return getPercentage(Object.values(completionCount).length - 1, sum);
    }
    return 0;
  }, [completionCount]);

  useEffect(() => {
    if (user.currentEnterprise?.id) {
      initConnections(
        { enterpriseId: user.currentEnterprise.id },
        {
          fields: [
            'id',
            'status',
            'email',
            'provider_id',
            'provider_name',
            'customer_id',
            'customer_name',
            'config_id',
            'customer_identification_number',
            'provider_identification_number',
          ],
          search: {
            provider_id: user.currentEnterprise.id,
            status: {
              $or: [
                ConnectionStatusToInt.UNSEEN,
                ConnectionStatusToInt.PENDING,
                ConnectionStatusToInt.IN_PROGRESS,
                ConnectionStatusToInt.DONE,
              ],
            },
          },
        },
      );
      initComplianceCount({ providerId: user.currentEnterprise.id });
      initCustomersCount({ enterpriseId: user.currentEnterprise.id, query: { is_connected: 'true' } });
      initCompletionCount({ enterpriseId: user.currentEnterprise.id });
      initMissions(
        {},
        { fields: ['id', 'status'], search: { vendor_enterprise_id: user.currentEnterprise.id } },
      );
      initContracts({ query: { enterprise_id: user.currentEnterprise.id } });
      initInvoices({ query: { vendor_id: user.currentEnterprise.id } });
    }
  }, [user?.currentEnterprise?.id]);

  const contractsByState = useMemo(() => {
    if (contracts.length) {
      return contracts.reduce((acc: Record<string, Contract[]>, contract: Contract) => {
        if (contract.state) {
          acc[contract.state] = acc[contract.state]?.length
            ? [...acc[contract.state], contract] : [contract];
        } else {
          acc.noState = acc.noState?.length ? [...acc.noState, contract] : [contract];
        }
        return acc;
      }, {});
    }
    return {};
  }, [contracts]);

  return (
    <Container fluid className="dashboard h-100">
      <Row className="border-bottom">
        <Col xxl="8" className="p-5">
          <h2 className="dashboard__title h1">
            {t('Dashboard.title', 'Tableau de bord')}
          </h2>
          <Row>
            {
              loadingCompliance || loadingCompletion ? (
                <div className="h-100 d-flex justify-content-center align-items-center">
                  <Spinner />
                </div>
              ) : (
                <>
                  <Col lg="4" className="mb-3 mb-lg-0">
                    <Link to={`/${app}/enterprises/${user.currentEnterprise?.id}`}>
                      <AWMiniCard
                        title={t('Dashboard.generalInformations')}
                        icon={InformationSVG}
                        progress={completion}
                        color={getColor(completion)}
                      >
                        {
                          t(
                            'Dashboard.generatInformationsText',
                            `Les informations de votre entreprises permettent de vous contacter
                            et de mieux vous connaitre. Elles sont essentielles pour vos clients.`,
                          )
                        }
                      </AWMiniCard>
                    </Link>
                  </Col>
                  <Col lg="4" className="mb-3 mb-lg-0">
                    <Link to={`/${app}/v1/addworking/enterprise/${user.currentEnterprise?.id}/document#nav-documents-legaux-tab`}>
                      <AWMiniCard
                        title={t('Dashboard.conformityDocuments', 'Documents de conformité')}
                        icon={ComplianceSVG}
                        progress={allCompliance}
                        color={getColor(allCompliance)}
                      >
                        {t(
                          'Dashboard.conformityDocumentsText',
                          `Les documents de conformité sont exigés par vos clients.
                          Ils sont vérifiés par les équipes AddWorking puis partagés avec vos clients.`,
                        )}
                      </AWMiniCard>
                    </Link>
                  </Col>
                  <Col lg="4">
                    <Link to={`/${app}/v1/addworking/enterprise/${user.currentEnterprise?.id}/passwork`}>
                      <AWMiniCard
                        className="h-100"
                        title={t('Dashboard.competencies', 'Métiers et compétences')}
                        icon={JobSVG}
                        color={AWColors.Grey}
                      >
                        {t(
                          'Dashboard.jobsAndCompetencies',
                          `Chacun de vos clients peut vous demander de préciser
                          vos différentes compétences afin de pouvoir vous 
                          proposer des missions en rapport avec celles-ci.`,
                        )}
                      </AWMiniCard>
                    </Link>
                  </Col>
                </>
              )
            }
          </Row>
        </Col>
        <Col xxl="4" className="dashboard--white d-flex flex-column flew-grow-1 p-5">
          {
            loadingCompliance ? (
              <div className="h-100 d-flex justify-content-center align-items-center">
                <Spinner />
              </div>
            ) : (
              <>
                <h2 className="dashboard__title h1">
                  {t('Dashboard.compliance', 'Conformité')}
                </h2>
                <Row className="flex-grow-1">
                  <Col
                    md="6"
                    className="d-flex flex-column flex-grow-1 align-items-center mb-4 mb-md-0"
                  >
                    <CircularProgress
                      percentage={compliance.legal}
                    />
                    <p className="mb-auto mt-3 text-center">
                      {t('Dashboard.legalConformity', 'Conformité légale')}
                    </p>
                  </Col>
                  <Col
                    md="6"
                    className="d-flex flex-column flex-grow-1 align-items-center"
                  >
                    <CircularProgress
                      percentage={compliance.business}
                    />
                    <div className="mb-auto mt-3 text-center">
                      <p className="mb-0">
                        {t('Dashboard.extendedConformity', 'Conformité étendue')}
                      </p>
                      <p className="mb-0">
                        {t('Dashboard.clientRequirements', '(exigences clients)')}
                      </p>
                    </div>
                  </Col>
                </Row>
              </>
            )
          }
        </Col>
      </Row>
      <Row className="flex-grow-1">
        <Col xxl="8" className="p-5">
          <h2 className="dashboard__title h1">
            {t('Dashboard.activity', 'Mon activité')}
          </h2>
          <Row>
            <Col md={3} xs={6} className="mt-3">
              <Link to={`/${app}/v1/mission`}>
                <AWMicroCard
                  title={t('MenuTabs.missions')}
                  number={missions.length}
                  color={AWColors.Blue}
                  className="pointer h-100"
                />
              </Link>
            </Col>
            <Col md={3} xs={6} className="mt-3">
              <Link to={`/${app}/v1/contract`}>
                <AWMicroCard
                  title={t('MenuTabs.contracts')}
                  number={contracts.length}
                  color={AWColors.Blue}
                  className="pointer h-100"
                />
              </Link>
            </Col>
            <Col md={3} xs={6} className="mt-3">
              <Link to={`/${app}/v1/contract?filter%5Bstates%5D%5B%5D=waiting_for_signature&`}>
                <AWMicroCard
                  title={t('Contracts.toSign')}
                  number={contractsByState.to_sign?.length || 0}
                  color={AWColors.Blue}
                  className="pointer h-100"
                />
              </Link>
            </Col>
            <Col md={3} xs={6} className="mt-3">
              <Link to={`/${app}/v1/contract?filter%5Bstates%5D%5B%5D=active&`}>
                <AWMicroCard
                  title={t('Contracts.active')}
                  number={contractsByState.active?.length || 0}
                  color={AWColors.Blue}
                  className="pointer h-100"
                />
              </Link>
            </Col>
            <Col md={3} xs={6} className="mt-3">
              <Link to={`/${app}/v1/addworking/enterprise/${user.currentEnterprise?.id}/inbound-invoice`}>
                <AWMicroCard
                  title={t('MenuTabs.invoices')}
                  number={invoices?.length || 0}
                  color={AWColors.Blue}
                  className="pointer h-100"
                />
              </Link>
            </Col>
          </Row>
        </Col>
        <Col xxl="4" className="dashboard--white p-5">
          <h2 className="dashboard__title h1 mb-3">
            {t('Categories.opportunities')}
          </h2>
          <Row>
            <Col
              sm="6"
              lg="3"
              xxl="6"
              className="mb-3 mb-lg-0 mb-xxl-3"
            >
              <Link to={`/${app}/v1/sector/offer`}>
                <AWMicroCard
                  title={t('Dashboard.missionProposals')}
                  number={0}
                  color={AWColors.Red}
                />
              </Link>
            </Col>
            <Col
              sm="6"
              lg="3"
              xxl="6"
              className="mb-3 mb-sm-0"
            >
              <Link to={`/${app}/connections`}>
                <AWMicroCard
                  title={t('Dashboard.clientInvitations', 'Invitations')}
                  number={connections?.length || 0}
                  color={AWColors.Blue}
                  className="pointer"
                />
              </Link>
            </Col>
            <Col
              sm="6"
              lg="3"
              xxl="6"
            >
              <Link to={`/${app}/v1/enterprise/${user.currentEnterprise?.id}/member`}>
                <AWMicroCard
                  title={t('Dashboard.collaboratorInvitations', 'Invitations de collaborateurs')}
                  number={0}
                  color={AWColors.LightGrey}
                />
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
