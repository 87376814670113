import { SessionUser } from '@root/interfaces/cognito.interface';
import dashboard from '@root/assets/dashboard.svg';
import { Menu } from '@root/interfaces/menu.interface';
import i18n from '@root/locales/i18n';
import information from '@root/assets/information.svg';
import job from '@root/assets/job.svg';
import mission from '@root/assets/mission.svg';
import contract from '@root/assets/contract.svg';
import bill from '@root/assets/bill.svg';
import invitation from '@root/assets/invitation.svg';
import document from '@root/assets/conformity.svg';
import candidat from '@root/assets/candidat.svg';
import customer from '@root/assets/customer.svg';
import approval from '@root/assets/approval.svg';
import { GUID_ID } from '@root/helpers/patterns';

const IS_PROD_REGEXP = /prod(uction)?/;

const REPORTING_USERS = [
  'julie+engie@addworking.com',
  'mendy@addworking.com',
];

export const buildMenuCustomer = (user: SessionUser): Menu[] => {
  const basePath = '/customer';
  const tabs = [
    {
      title: '',
      subMenus: [
        {
          title: i18n.t('MenuTabs.dashboard', 'Tableau de bord'),
          icon: dashboard,
          linkTo: `${basePath}/v1/dashboard`,
        },
      ],
    },
    {
      title: i18n.t('MenuTabs.company', 'Mon entreprise'),
      subMenus: [
        {
          title: i18n.t('MenuTabs.informations', 'Informations générales'),
          icon: information,
          linkTo: `${basePath}/enterprises/${user.currentEnterprise?.id}`,
        },
        {
          title: i18n.t('MenuTabs.members'),
          icon: customer,
          linkTo: `${basePath}/v1/enterprise/${user.currentEnterprise?.id}/member`,
        },
      ],
    },
    {
      title: i18n.t('MenuTabs.activity', 'Mon activité'),
      subMenus: [
        {
          title: i18n.t('MenuTabs.providers', 'Sous-traitants'),
          icon: document,
          linkTo: `${basePath}/providers`,
          matchingPaths: [
            `${basePath}/providers/${GUID_ID}`,
          ],
        },
        {
          title: i18n.t('MenuTabs.contracts', 'Contrats'),
          icon: contract,
          linkTo: `${basePath}/v1/contract`,
        },
        {
          title: i18n.t('MenuTabs.workfields', 'Chantiers'),
          icon: mission,
          linkTo: `${basePath}/v1/workfield`,
          matchingPaths: [
            `${basePath}/v1/workfield/${GUID_ID}#nav-missions-tab`,
          ],
        },
        {
          title: i18n.t('MenuTabs.invoices', 'Factures'),
          icon: bill,
          linkTo: `${basePath}/v1/inbound-invoice`,
        },
        {
          title: i18n.t('MenuTabs.missions', 'Missions'),
          icon: mission,
          linkTo: `${basePath}/v1/mission`,
        },
        {
          title: i18n.t('MenuTabs.missionTracking', 'Suivis de mission'),
          icon: mission,
          linkTo: `${basePath}/v1/mission/tracking `,
        },
      ],
    },
    {
      title: i18n.t('MenuTabs.opportunities', 'Opportunités'),
      subMenus: [
        {
          title: i18n.t('MenuTabs.providers', 'Sous-traitants'),
          icon: invitation,
          linkTo: `${basePath}/connections`,
          matchingPaths: [
            `${basePath}/connections/${GUID_ID}`,
          ],
        },
        {
          title: i18n.t('MenuTabs.missionProposals'),
          icon: job,
          linkTo: `${basePath}/v1/sector/offer`,
        },
      ],
    },
  ];
  if (!process.env.REACT_APP_ENV || !process.env.REACT_APP_ENV.match(IS_PROD_REGEXP)) {
    tabs[2].subMenus.splice(4, 0, {
      title: i18n.t('MenuTabs.approvals', "Demandes d'agrément"),
      icon: approval,
      linkTo: `${basePath}/approvals`,
    });
  }
  if (user.email && REPORTING_USERS.includes(user.email)) {
    // Tab reporting for demo
    tabs[2].subMenus.push({
      title: i18n.t('MenuTabs.reporting'),
      icon: dashboard,
      linkTo: `${basePath}/reporting`,
    });
  }
  return tabs;
};

export const buildMenuProvider = (user: SessionUser): Menu[] => {
  const basePath = '/provider';
  return [
    {
      title: '',
      subMenus: [
        {
          title: i18n.t('MenuTabs.dashboard', 'Tableau de bord'),
          icon: dashboard,
          linkTo: basePath,
        },
      ],
    },
    {
      title: i18n.t('MenuTabs.company', 'Mon entreprise'),
      subMenus: [
        {
          title: i18n.t('MenuTabs.informations', 'Informations générales'),
          icon: information,
          linkTo: `${basePath}/enterprises/${user.currentEnterprise?.id}`,
        },
        {
          title: i18n.t('MenuTabs.members'),
          icon: customer,
          linkTo: `${basePath}/v1/enterprise/${user.currentEnterprise?.id}/member`,
        },
        {
          title: i18n.t('MenuTabs.documents', 'Documents'),
          icon: document,
          linkTo: `${basePath}/v1/addworking/enterprise/${user.currentEnterprise?.id}/document#nav-documents-legaux-tab`,
        },
        {
          title: i18n.t('MenuTabs.skills', 'Métiers et compétences'),
          icon: job,
          linkTo: `${basePath}/v1/addworking/enterprise/${user.currentEnterprise?.id}/passwork`,
        },
      ],
    },
    {
      title: i18n.t('MenuTabs.activity', 'Activité'),
      subMenus: [
        {
          title: i18n.t('MenuTabs.activeCustomers', 'Clients actifs'),
          icon: customer,
          linkTo: `${basePath}/customers`,
          matchingPaths: [
            `${basePath}/customers/${GUID_ID}`,
          ],
        },
        {
          title: i18n.t('MenuTabs.missions', 'Missions'),
          icon: mission,
          linkTo: `${basePath}/v1/mission`,
        },
        {
          title: i18n.t('MenuTabs.contracts', 'Contrats'),
          icon: contract,
          linkTo: `${basePath}/v1/contract`,
        },
        {
          title: i18n.t('MenuTabs.approvals', "Demandes d'agrément"),
          icon: approval,
          linkTo: `${basePath}/approvals`,
        },
        {
          title: i18n.t('MenuTabs.invoices', 'Factures'),
          icon: bill,
          linkTo: `${basePath}/v1/addworking/enterprise/${user.currentEnterprise?.id}/inbound-invoice`,
        },
      ],
    },
    {
      title: i18n.t('Categories.opportunities'),
      subMenus: [
        {
          title: i18n.t('MenuTabs.missionProposals'),
          icon: mission,
          linkTo: `${basePath}/v1/sector/offer`,
        },
        {
          title: i18n.t('MenuTabs.customers', 'Clients'),
          icon: candidat,
          linkTo: `${basePath}/connections`,
          notificationKey: 'connections',
          matchingPaths: [
            `${basePath}/connections/${GUID_ID}`,
          ],
        },
      ],
    },
  ];
};
